import { PluginState } from '@grafana/data';
import { config, CanobiBootConfig } from '@grafana/runtime';
// Legacy binding paths
export { config, CanobiBootConfig as Settings };

let grafanaConfig: CanobiBootConfig = config;

export default grafanaConfig;

export const getConfig = () => {
  return grafanaConfig;
};

export const updateConfig = (update: Partial<CanobiBootConfig>) => {
  grafanaConfig = {
    ...grafanaConfig,
    ...update,
  };
};

// The `enable_alpha` flag is not exposed directly, this is equivalent
export const hasAlphaPanels = Boolean(config.panels?.debug?.state === PluginState.alpha);
