import { css } from '@emotion/css';
import React from 'react';

import { CanobiTheme2, locale } from '@grafana/data';

import { stylesFactory, useStyles2 } from '../../themes';

const getStyles = stylesFactory((theme: CanobiTheme2) => {
  return {
    counter: css`
      label: counter;
      margin-left: ${theme.spacing(1)};
      border-radius: ${theme.spacing(3)};
      background-color: ${theme.colors.action.hover};
      padding: ${theme.spacing(0.25, 1)};
      color: ${theme.colors.text.secondary};
      font-weight: ${theme.typography.fontWeightMedium};
      font-size: ${theme.typography.size.sm};
    `,
  };
});

export interface CounterProps {
  value: number;
}

export const Counter = ({ value }: CounterProps) => {
  const styles = useStyles2(getStyles);

  return <span className={styles.counter}>{locale(value, 0).text}</span>;
};
