import React, { useContext } from 'react';

import { CanobiConfig } from '@grafana/data';
import { LocationService } from '@grafana/runtime/src/services/LocationService';
import { BackendSrv } from '@grafana/runtime/src/services/backendSrv';

import { AppChromeService } from '../components/AppChrome/AppChromeService';
import { KeybindingSrv } from '../services/keybindingSrv';

export interface CanobiContextType {
  backend: BackendSrv;
  location: LocationService;
  config: CanobiConfig;
  chrome: AppChromeService;
  keybindings: KeybindingSrv;
}

export const CanobiContext = React.createContext<CanobiContextType | undefined>(undefined);

export function useCanobi(): CanobiContextType {
  const context = useContext(CanobiContext);
  if (!context) {
    throw new Error('No CanobiContext found');
  }
  return context;
}
